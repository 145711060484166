import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faLocationDot, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import card1 from 'assets/images/landing/card1.jpg';
import card2 from 'assets/images/landing/card2.jpg';
import card3 from 'assets/images/landing/card3.jpg';
import leaves from 'assets/images/landing/leaves.png';
import ButtonPrimary from 'components/common/Button';
import ButtonPrimaryOutline from 'components/common/Button/ButtonPrimary';
import { Link } from 'react-router-dom';


export default function Cards() {
  return (
    <div className="flex flex-col">
      <div className="bg-black">
        <PaddedPage className="flex flex-col items-stretch gap-8 py-2">
          <div className="flex flex-col justify-center text-center">
            <h3 className="text-2xl text-white font-med">Cold Acre is now a Yukon First Nation owned company. Find out what this means for us, and our parent company, Na-Cho Nyäk Dun Development Corporation.</h3>
            <a href="https://www.nnddc.ca/post/investing-in-self-determination-and-food-sovereignty-na-cho-ny%C3%A4k-dun-development-corporation-acquir"><ButtonPrimary className=" p-12 px-10 font-bold mt-4 border-white text-white hover:bg-med-grey">Find Out More</ButtonPrimary></a>
          </div>
        </PaddedPage>
      </div>
      <PaddedPage className="flex flex-col items-stretch gap-8">
        <div className="flex flex-col justify-center text-center pb-12">
          <h1 className="jumbo">ColdAcre seeks a solution that works for your community.</h1>
          <h3 className="text-med-grey text-2xl font-med">Lets find it together.</h3>
          <Link to="/about"><ButtonPrimary className=" p-12 px-10 font-bold mt-4 bg-light-grey hover:bg-med-grey">Get Started by Exploring Our Systems</ButtonPrimary></Link>
          {/* <h2 className="mt-2">
              Commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </h2> */}
        </div>
      </PaddedPage>
      <div className="h-32 -mt-40 border-b-2 border-black opacity-80 sm:hidden" >
        <img src={leaves} className="invert h-32 m-auto" alt="ColdAcre container farm"/>
      </div>
      <PaddedPage className="flex flex-col items-stretch gap-8">
        <div className="flex justify-between flex-col mt-12 gap-4 sm:mt-0 sm:gap-16">
          <div className="flex gap-12 sm:flex-col items-center">
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{ backgroundImage: `url(${card2})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLightbulb} />
            </div>
            <div>
              <h2>We're invested in your success.</h2>
              <h3 className="text-med-grey text-lg font-med">That's why we pride ourselves in being able to guarantee yields for our customers. Through full on-site training at our commercial farm, continued support, and our NEW 3-Year Warranty, we've made it our responsibility to support  each community we enter with net positive operations.</h3>
              <Link to="/coldacreplus" className="self-center"><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Find Out More</ButtonPrimary></Link>
            </div>
          </div>
          <div className="flex gap-12 sm:flex-col-reverse  items-center justify-end self-end  bg-light-grey sm:bg-white w-full">
            <div className="flex flex-col items-end sm:items-start">
              <h2>WinterHarvest Food Subscription </h2>
              <h3 className="text-med-grey text-lg font-med text-right sm:text-left">Explore our local Yukon food subscription box full of local<br></br> products and ColdAcre-grown ingredients.</h3>
              <a href="https://winterharvest.coldacre.ca" className=""><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Learn More</ButtonPrimary></a>
            </div>
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 flex sm:mt-0 justify-center bg-cover items-center"
              style={{ backgroundImage: `url(${card3})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLeaf} />
            </div>
          </div>
          <div className="flex gap-12 sm:flex-col items-center">
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{ backgroundImage: `url(${card1})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLocationDot} />
            </div>
            <div>
              <h2>We grow it all, and so can you.</h2>
              <h3 className="text-med-grey text-lg font-med">While some of our competitor systems are limited to growing leafy greens, ColdAcre mixes old and new technologies to grow all kinds of vegetables including fruiting crops - separating us from other hydroponic producers.</h3>
              <Link to="/about" className="self-center"><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Start Building your System Today</ButtonPrimary></Link>
            </div>
          </div>
        </div>
      </PaddedPage>
    </div>
  )
}
